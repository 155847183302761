import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@reach/router';

const SectionStockChronology = () => {
  const { t } = useTranslation('about-us');
  const loc = useLocation();
  const currentPath = loc.href;
  const isEnglish = currentPath.includes('/en/');

  const title = isEnglish
    ? 'Chronology of Listing'
    : '  Kronologi Pencatatan Saham';

  const paragprah = isEnglish
    ? `The company obtained the effective statement for the initial public offering of its shares to the public involving 3.948.133.300 shares, with par value of IDR 20 Rupiah per shares at offering price of IDR 100 Rupiah per share.
    `
    : `PT Multi Garam Utama Tbk telah melakukan penawaran umum perdana sahamnya kepada masyarakat, yaitu sejumlah 3.948.133.300 lembar saham dengan nilai nominal saham Rp. 20,- (dua puluh Rupiah) per lembar saham dengan harga penawaran sebesar Rp. 100,- (seratus Rupiah) per lembar. `;

  return (
    <div className="min-h-[60vh] py-3">
      <div>
        <div className="border-b py-3 mb-2">
          <h2 className="text-base md:text-2xl font-medium">
            {t('title kronologis-saham')}
          </h2>
        </div>
        <h2 style={{ fontSize: '18px', fontWeight: '600' }}>{title}</h2>
        <p>2023</p>
        <p style={{ textAlign: 'justify' }}>{paragprah}</p>
      </div>
    </div>
  );
};

export default SectionStockChronology;
