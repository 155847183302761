import React, { useState } from 'react';
import pdfIcon from 'images/icon/pdf-icon.svg';
import downloadIcon from 'images/icon/download-orange-ic.svg';
import Tabs from '../../../Tabs';
import { useLocation } from '@reach/router';

const SectionRUPS = ({ files }) => {
  const loc = useLocation();
  const currentPath = loc.href;
  const isEnglish = currentPath.includes('/en/');
  const tabTitles = isEnglish
    ? ['ANNOUNCEMENT', 'NOTICE INVITATION', 'ANNOUNCEMENT OF THE SUMMARY']
    : ['Pengumuman', 'Panggilan', 'Keputusan atau Notulen Rapat'];

  const filesEn = [
    {
      path: '/pdf/en/pengumuman_RUPS_Tahunan_FOLK-inggris.pdf',
      title: 'Annual General Meeting FOLK May 31, 2024',
    },
    {
      path: '/pdf/en/english-Pengumuman_RUPSLB_FOLK_2024.pdf',
      title: 'Announcement Extraordinary General Meeting FOLK 2024',
    },
  ];

  const filesId = [
    {
      path: '/pdf/id/pengumumanRUPSTahunanFOLK-indonesia.pdf',
      title: 'Pengumuman RUPST FOLK 31 Mei 2024',
    },
    {
      path: '/pdf/id/indo-Pengumuman-RUPSLB-FOLK-2024.pdf',
      title: 'Pengumuman RUPSLB FOLK 2024',
    },
  ];

  const filesToShow = isEnglish ? filesEn : filesId;

  const gmsNoticeFile = isEnglish
    ? [
        {
          path: '/pdf/en/EnglisFormulirsuaraRUPSTMultiGaramUtamaTbk.pdf',
          title: 'Voting Card',
        },
        {
          path: '/pdf/en/eng-Panggilan_RUPST_FOLK_2024.pdf',
          title: 'Invitation AGM FOLK 2024',
        },
        {
          path: '/pdf/en/eng-SuratKuasaRUPSTFOLK2024.pdf',
          title: 'POWER OF ATTORNEY AGM FOLK 2024',
        },
        {
          path: '/pdf/en/panggilanRUPSLBPTMultiGaramUtamaTbk-ingg.pdf',
          title:
            'Invitation To Extraordinary General Meeting Of Shareholders 22 August 2024',
        },
        {
          path: '/pdf/en/eng - Surat Kuasa RUPSLB FOLK 2024 (1).pdf',
          title: 'the power of attorney Extraordinary GMS August 22, 2024',
        },
        {
          path: '/pdf/en/english surat suara rupslb 2024.pdf',
          title: 'voting card Extraordinary GMS August 22, 2024',
        },
      ]
    : [
        {
          path: '/pdf/id/FormulirsuratsuaraRUPSTMultiGaramUtamaTbk.pdf',
          title: 'Kartu Surat Suara',
        },
        {
          path: '/pdf/id/ind-PanggilanRUPSTFOLK2024.pdf',
          title: 'Panggilan RUPS Tahunan FOLK 2024',
        },
        {
          path: '/pdf/id/ind-SuratKuasaRUPSTFOLK2024.pdf',
          title: 'Surat Kuasa RUPST FOLK 2024',
        },
        {
          path: '/pdf/id/panggilanRUPSLB PTMultiGaramUtamaTbk-ind.pdf',
          title: 'Panggilan RUPSLB 22 Agustus 2024',
        },
        {
          path: '/pdf/id/ind - Surat Kuasa RUPSLB FOLK 2024 (2).pdf',
          title: 'surat kuasa RUPSLB 22 agustus 2024',
        },
        {
          path: '/pdf/id/indo surat suara rupslb 2024.pdf',
          title: 'surat suara RUPSLB 22 agustus 2024',
        },
      ];

  const summaries = isEnglish
    ? [
        {
          title:
            'Summary of Minutes of the Annual General Meeting of Shareholders FOLK 2024',
          path: '/pdf/en/ing-Ringkasan-Risalah-RUPST-FOLK-2024.pdf',
        },
        {
          title: `The Summary of The Minutes of The Company's Extraordinary General Meeting of Shareholders FOLK - 22 August 2024`,
          path: '/pdf/en/ing-RingkasanRisalahRUPSLBFOLK22Agustus2024.pdf',
        },
      ]
    : [
        {
          title: 'Ringkasan Risalah RUPST FOLK 2024',
          path: '/pdf/id/ind-Ringkasan-Risalah-RUPST-FOLK-2024.pdf',
        },
        {
          title:
            'Ringkasan Risalah Rapat Umum Pemegang Luar Biasa FOLK - 22 Agustus 2024',
          path: '/pdf/id/ind - Ringkasan Risalah RUPSLB FOLK 22 Agustus 2024.pdf',
        },
      ];

  const [tabActive, setTabActive] = useState(tabTitles[0]);
  return (
    <div className="min-h-[60vh] py-3">
      <Tabs
        listOfTab={tabTitles}
        tabSelected={tabActive}
        handleSelectTab={(e) => setTabActive(e)}
      />
      {tabActive === tabTitles[0] && (
        <div className="mt-6 pl-6">
          <div className="border-b py-3 mb-2">
            <h2 className="text-base md:text-2xl font-medium">
              {isEnglish ? 'GMS' : 'RUPS'} {tabTitles[0]}
            </h2>
          </div>
          {filesToShow.map((file) => {
            return (
              <div className="flex justify-between py-3 md:py-2 cursor-pointer">
                <div className="flex items-center gap-3">
                  <img src={pdfIcon} alt="pdf-icon" className="w-4" />
                  <a href={file.path} target="_blank">
                    <p className="text-sm md:text-base font-[200] cursor-pointer">
                      {file.title}
                    </p>
                  </a>
                </div>
                <img
                  src={downloadIcon}
                  alt="icon-download"
                  className="block md:hidden"
                />
              </div>
            );
          })}
        </div>
      )}
      {tabActive === tabTitles[1] && (
        <div className="mt-6 pl-6">
          <div className="border-b py-3 mb-2">
            <h2 className="text-base md:text-2xl font-medium">
              {isEnglish ? 'GMS' : 'RUPS'} {tabTitles[1]}
            </h2>
          </div>
          {gmsNoticeFile.map((file) => {
            return (
              <div
                key={file.title}
                className="flex justify-between py-3 md:py-2 cursor-pointer"
              >
                <div className="flex items-center gap-3">
                  <img src={pdfIcon} alt="pdf-icon" className="w-4" />
                  <a href={file.path} target="_blank">
                    <p className="text-sm md:text-base font-[200] cursor-pointer">
                      {file.title}
                    </p>
                  </a>
                </div>
                <img
                  src={downloadIcon}
                  alt="icon-download"
                  className="block md:hidden"
                />
              </div>
            );
          })}
        </div>
      )}
      {tabActive === tabTitles[2] && (
        <div className="mt-6 pl-6">
          <div className="border-b py-3 mb-2">
            <h2 className="text-base md:text-2xl font-medium">
              {isEnglish ? 'GMS' : 'RUPS'} {tabTitles[2]}
            </h2>
          </div>
          {summaries.map((item, idx) => (
            <div
              key={idx.toString()}
              className="flex justify-between py-3 md:py-2 cursor-pointer"
            >
              <div className="flex items-center gap-3">
                <img src={pdfIcon} alt="pdf-icon" className="w-4" />
                <a href={item.path} target="_blank">
                  <p className="text-sm md:text-base font-[200] cursor-pointer">
                    {item.title}
                  </p>
                </a>
              </div>
              <img
                src={downloadIcon}
                alt="icon-download"
                className="block md:hidden"
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SectionRUPS;
