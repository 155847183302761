import React from 'react';
import { useTranslation } from 'react-i18next';
import struktur from 'images/AboutUs/struktur-pemegang-saham.png';

const SectionShareholderInformation = () => {
  const { t } = useTranslation('about-us');

  return (
    <div className="min-h-[60vh] py-3">
      <div>
        <div className="border-b py-3 mb-2">
          <h2 className="text-base md:text-2xl font-medium">
            {t('title informasi-pemegang-saham')}
          </h2>
        </div>
        <img
          src={struktur}
          className="mb-16 lg:mb-24 mt-10"
          style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
          alt="struktur"
        />
      </div>
    </div>
  );
};

export default SectionShareholderInformation;
