import React from 'react';
import Pages from '../components/Pages';
import InvestorRelations from '../components/pages/InvestorRelations';
import { graphql } from 'gatsby';

const InvestorRelationsPage = ({ data }) => {
  return (
    <div className="bg-black text-white">
      <Pages title="FOLK - Investor Relations">
        <InvestorRelations data={data} />
      </Pages>
    </div>
  );
};

export const query = graphql`
  query investorRelationsFiles($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contentfulHubunganInvestorFile {
      informasiKeuanganKuartal {
        url
        title
      }
      informasiKeuanganTahunan {
        url
        title
      }
      keterbukaanInformasiAksiKorporasi {
        url
        title
      }
      keterbukaanInformasiInfomasiMaterial {
        url
        title
      }
      laporanTahunan {
        url
        title
      }
      prospektusFile {
        url
        title
      }
      rupsKeputusan {
        url
        title
      }
      rupsPanggilan {
        url
        title
      }
      rupsPengumuman {
        url
        title
      }
    }
  }
`;

export default InvestorRelationsPage;
