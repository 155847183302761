import React from 'react';
import pdfIcon from 'images/icon/pdf-icon.svg';
import downloadIcon from 'images/icon/download-orange-ic.svg';
import { useTranslation } from 'react-i18next';

const prospektusMaterials = {
  en: [
    {
      title: 'PT Multi Garam Utama Tbk 2023',
      path: 'BUKU_PROSPEKTUS_GMU 2023_OK_lapor.pdf',
    },
  ],
  id: [
    {
      title: 'PT Multi Garam Utama Tbk 2023',
      path: 'BUKU_PROSPEKTUS_GMU 2023_OK_lapor.pdf',
    },
  ],
};

const SectionProspektus = ({ files }) => {
  const { t } = useTranslation('about-us');

  return (
    <div className="min-h-[60vh] py-3">
      <div>
        <div className="border-b py-3">
          <h2 className="text-base md:text-2xl font-medium">
            {t('title prospektus')}
          </h2>
        </div>
        <div className="py-5 px-3 ">
          {prospektusMaterials['id'].map((material) => {
            return (
              <div className="flex justify-between py-3 md:py-2 cursor-pointer">
                <div className="flex items-center gap-3">
                  <img src={pdfIcon} alt="pdf-icon" className="w-4" />
                  <a href={`/pdf/id/${material.path}`} target="_blank">
                    <p className="text-sm md:text-base font-[200] cursor-pointer">
                      {material.title}
                    </p>
                  </a>
                </div>
                <img
                  src={downloadIcon}
                  alt="icon-download"
                  className="block md:hidden"
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SectionProspektus;
