import React, { useState } from 'react';
import CustomContainer from '../../CustomContainer';
import background from '../../../images/governance/banner-governance.png';
import '../Governance/governance.css';
import SidebarNavigation from '../Governance/components/SidebarNavigation';
import { useMediaQuery, useWindowWidth } from '../../hooks/useResponsive';
import Collapse from '../Governance/components/Collapse';
import SectionProspektus from './components/SectionProspektus';
import SectionFinanceInformation from './components/SectionFinanceInformation';
import SectionAnnualReport from './components/SectionAnnualReport';
import SectionRUPS from './components/SectionRUPS';
import { useTranslation } from 'react-i18next';
import SectionInformationDisclosure from './components/SectionInformationDisclosure';
import SectionShareholderInformation from './components/SectionShareholderInformation';
import SectionStockChronology from './components/SectionStockChronology';
import SectionSupportingInstitutions from '../Governance/components/SectionSupportingInstitutions';

const listNavSidebar = [
  {
    name: 'Prospektus',
    id: 'prospektus',
  },
  {
    name: 'Informasi Keuangan dan Presentasi',
    id: 'informasi-keuangan',
  },
  {
    name: 'Laporan Tahunan',
    id: 'laporan-tahunan',
  },
  {
    name: 'RUPS',
    id: 'rups',
  },
  {
    name: 'Keterbukaan Informasi',
    id: 'keterbukaan-informasi',
  },
  {
    name: 'Informasi Pemegang Saham',
    id: 'informasi-pemegang-saham',
  },
  {
    name: 'Kronologis Saham',
    id: 'kronologis-saham',
  },
];

const GovernancePage = ({ data }) => {
  const windowWidth = useWindowWidth();
  const mediaQuery = useMediaQuery();
  const [selectedNav, setSelectedNav] = useState('prospektus');
  const files = data?.contentfulHubunganInvestorFile;

  const { t } = useTranslation('about-us');

  return (
    <div className="min-h-screen relative">
      <img
        src={background}
        alt="background"
        className="absolute top-0 left-0 h-52 md:h-[300px] w-full object-cover"
      />
      <CustomContainer className="pt-20 h-full relative">
        <div className="h-32 md:h-56 flex items-center">
          <h1 className="text-2xl md:text-4xl md:mt-16">
            {t('investorRelations')}
          </h1>
        </div>
        {windowWidth > mediaQuery.sm && (
          <div className="flex min-h-screen relative py-10 gap-14">
            <SidebarNavigation
              listNavSidebar={listNavSidebar}
              selectedItem={selectedNav}
              handleSelected={(e) => setSelectedNav(e)}
            />
            <div className="flex-1">
              {selectedNav === 'prospektus' && (
                <SectionProspektus files={files} />
              )}
              {selectedNav === 'informasi-keuangan' && (
                <SectionFinanceInformation files={files} />
              )}
              {selectedNav === 'laporan-tahunan' && (
                <SectionAnnualReport files={files} />
              )}
              {selectedNav === 'rups' && <SectionRUPS files={files} />}
              {selectedNav === 'keterbukaan-informasi' && (
                <SectionInformationDisclosure files={files} />
              )}
              {selectedNav === 'informasi-pemegang-saham' && (
                <SectionShareholderInformation />
              )}
              {selectedNav === 'kronologis-saham' && <SectionStockChronology />}
              {selectedNav === 'lembaga-penunjang' && (
                <SectionSupportingInstitutions />
              )}
            </div>
          </div>
        )}
      </CustomContainer>
      <div className="block lg:hidden">
        {listNavSidebar?.map((nav, idx) => (
          <Collapse title={t(`title ${nav.id}`)} key={idx.toString()}>
            {nav.id === 'prospektus' && <SectionProspektus files={files} />}
            {nav.id === 'informasi-keuangan' && (
              <SectionFinanceInformation files={files} />
            )}
            {nav.id === 'laporan-tahunan' && (
              <SectionAnnualReport files={files} />
            )}
            {nav.id === 'rups' && <SectionRUPS files={files} />}
            {nav.id === 'keterbukaan-informasi' && (
              <SectionInformationDisclosure files={files} />
            )}
            {nav.id === 'informasi-pemegang-saham' && (
              <SectionShareholderInformation />
            )}
            {nav.id === 'kronologis-saham' && <SectionStockChronology />}
            {nav.id === 'lembaga-penunjang' && (
              <SectionSupportingInstitutions />
            )}
          </Collapse>
        ))}
      </div>
    </div>
  );
};

export default GovernancePage;
