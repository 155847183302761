import React, { useState } from 'react';
import pdfIcon from 'images/icon/pdf-icon.svg';
import downloadIcon from 'images/icon/download-orange-ic.svg';
import Tabs from '../../../Tabs';
import { useLocation } from '@reach/router';

const informationTransparencyMaterials = {
  en: [
    {
      title: 'Information Transparency PT AIM - PT MGU',
      path: 'KI_-_PT_AIM-_PT_MGU_Pinjaman_Final.pdf',
    },
    {
      title: 'Information Transparency PT DGI - PT MGU',
      path: 'KI_-_PT_DGI_-_PT_MGU_Pinjaman_Final.pdf',
    },
    {
      title: 'Information Transparency PT SYCA - PT MGU',
      path: 'KI_-_PT_SYCA_-_PT_MGU_Pinjaman_Final.pdf',
    },
    {
      title: 'Going Public on August 2023',
      path: 'IPO_Pada_Agustus_2023_eng.pdf',
    },
  ],
  id: [
    {
      title: 'Keterbukaan Informasi PT AIM - PT MGU',
      path: 'KI_-_PT_AIM-_PT_MGU_Pinjaman_Final.pdf',
    },
    {
      title: 'Keterbukaan Informasi PT DGI - PT MGU',
      path: 'KI_-_PT_DGI_-_PT_MGU_Pinjaman_Final.pdf',
    },
    {
      title: 'Keterbukaan Informasi PT SYCA - PT MGU',
      path: 'KI_-_PT_SYCA_-_PT_MGU_Pinjaman_Final.pdf',
    },
    {
      title: 'IPO pada Agustus 2023',
      path: 'IPO_Pada_Agustus_2023.pdf',
    },
  ],
};

const SectionInformationDisclosure = ({ files }) => {
  const loc = useLocation();
  const currentPath = loc.href;
  const isEnglish = currentPath.includes('/en/');
  const tabTitles = isEnglish
    ? ['Material Information', 'Corporate Action']
    : ['Informasi Material', 'Aksi Korporasi'];
  const [tabActive, setTabActive] = useState(tabTitles[0]);

  return (
    <div className="min-h-[60vh] py-3">
      <Tabs
        listOfTab={tabTitles}
        tabSelected={tabActive}
        handleSelectTab={(e) => setTabActive(e)}
      />
      {tabActive === tabTitles[0] && (
        <div className="mt-6 pl-6">
          <div className="border-b py-3 mb-2">
            <h2 className="text-base md:text-2xl font-medium">
              {tabTitles[0]}
            </h2>
          </div>
          {informationTransparencyMaterials[isEnglish ? 'en' : 'id'].map(
            (material) => {
              return (
                <div className="flex justify-between py-3 md:py-2 cursor-pointer">
                  <div className="flex items-center gap-3">
                    <img src={pdfIcon} alt="pdf-icon" className="w-4" />
                    <a
                      href={`/pdf/${isEnglish ? 'en' : 'id'}/${material.path}`}
                      target="_blank"
                    >
                      <p className="text-sm md:text-base font-[200] cursor-pointer">
                        {material.title}
                      </p>
                    </a>
                  </div>
                  <img
                    src={downloadIcon}
                    alt="icon-download"
                    className="block md:hidden"
                  />
                </div>
              );
            },
          )}
        </div>
      )}
      {tabActive === tabTitles[1] && (
        <div className="mt-6 pl-6">
          <div className="border-b py-3 mb-2">
            <h2 className="text-base md:text-2xl font-medium">
              {tabTitles[1]}
            </h2>
          </div>
          {files?.keterbukaanInformasiAksiKorporasi.map((item, idx) => (
            <div
              key={idx.toString()}
              className="flex justify-between py-3 md:py-2 cursor-pointer"
            >
              <div className="flex items-center gap-3">
                <img src={pdfIcon} alt="pdf-icon" className="w-4" />
                <a href={item.url} target="_blank">
                  <p className="text-sm md:text-base font-[200] cursor-pointer">
                    {item.title}
                  </p>
                </a>
              </div>
              <img
                src={downloadIcon}
                alt="icon-download"
                className="block md:hidden"
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SectionInformationDisclosure;
