import React from 'react';
import dotIcon from 'images/icon/dot-icon.svg';

const CardTab = ({ title, isActive, handleSelectTab }) => {
  return (
    <div
      onClick={handleSelectTab}
      className="flex items-center gap-3 cursor-pointer"
    >
      <img
        src={dotIcon}
        alt="dot-icon"
        className={`${isActive ? 'block' : 'hidden'}`}
      />
      <p
        className={`${
          isActive ? ' opacity-100' : ' opacity-40'
        } cursor-pointer`}
      >
        {title}
      </p>
    </div>
  );
};

const Tabs = ({ listOfTab, handleSelectTab, tabSelected }) => {
  return (
    <div className="flex items-center gap-10">
      {listOfTab.map((tab, idx) => (
        <CardTab
          title={tab}
          key={idx.toString()}
          isActive={tab === tabSelected}
          handleSelectTab={() => handleSelectTab(tab)}
        />
      ))}
    </div>
  );
};

export default Tabs;
