import React from 'react';
import pdfIcon from 'images/icon/pdf-icon.svg';
import downloadIcon from 'images/icon/download-orange-ic.svg';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@reach/router';

const dataAnnualReport = [
  {
    name: 'Laporan Tahunan',
    link: '/',
  },
  {
    name: 'Laporan Tahunan',
    link: '/',
  },
  {
    name: 'Laporan Tahunan',
    link: '/',
  },
  {
    name: 'Laporan Tahunan',
    link: '/',
  },
];

const SectionAnnualReport = ({ files }) => {
  const { t } = useTranslation('about-us');
  const loc = useLocation();
  const currentPath = loc.href;
  const isEnglish = currentPath.includes('/en/');

  return (
    <div className="min-h-[60vh] py-3">
      <div>
        <div className="border-b py-3">
          <h2 className="text-base md:text-2xl font-medium">
            {t('title laporan-tahunan')}
          </h2>
        </div>
        <div className="py-5 px-3 ">
          <div className="flex justify-between py-3 md:py-2 cursor-pointer">
            <div className="flex items-center gap-3">
              <img src={pdfIcon} alt="pdf-icon" className="w-4" />
              <a
                href="/pdf/en/AnnualReportdanSustainabilityReportFOLKGroup2023.pdf"
                target="_blank"
              >
                <p className="text-sm md:text-base font-[200] cursor-pointer">
                  {isEnglish
                    ? 'Annual Report dan Sustainability Report FOLK 2023'
                    : 'Laporan Tahunan dan Laporan Keberlanjutan FOLK 2023'}
                </p>
              </a>
            </div>
            <img
              src={downloadIcon}
              alt="icon-download"
              className="block md:hidden"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionAnnualReport;
